import React from 'react'
import { FaArrowCircleRight } from 'react-icons/fa'

export default [
  {
    id: '1',
    icon: <FaArrowCircleRight />,
    ParaPoint: '⟶',
    ParaOne: "If this statement is so critically true, then what is it about “spend analytics” which drive a vast majority of CPO’s to check the same proverbial box?  The answer to this question sits in today's era of digitization.  Given Deloitte’s statement “Procurement touches every part of business from the board to the front-line operator”, gaining access to deep spend analytics literally provides value back to the entire company.  That’s exactly why Procurement digitization has become a must-have issue.  Let’s use Raindrop as an example, a complete Enterprise Spend Management platform, capable of providing rapid spend analytics and information.  With Raindrop, you can find new insights and spend patterns into how and where the money is flowing out of your company, enabling the CPO’s organization to provide significant financial value to the company’s margins.  In a very real way, Procurement, as an organizational function, adds to the bottom line.  Therefore, investing in Enterprise Spend Management digitization solutions literally generates cash from operations.",
    ParaTwo: "But what makes a spend management solution truly provide this value?  Ensuring clean and complete information is used as the starting point as pulled from various technology systems (such as ERP, CMS, HR systems, etc.) is critical.  After all, good and complete data results in good and complete outcomes.  Or more simply put, “quality in, quality out”.  These multiple systems are where “spend leakage” happens, especially when each system is not fully integrated.  Think of the ERP system not being in sync with the contract management system.  Having gaps in systems, provides gaps in knowledge.",
    ParaThree: "This is why Raindrop’s “single pane of glass” provides a single unified and normalized view with actionable insights.  This is the difference between data and information.  Data is nice, but it must provide usable insights.  Failure to gain beneficial information out of your various point-solution systems, means the CPO’s organization will be burdened with an unusable set of numbers, and not fully able to focus on strategic value delivery back to the company.  This is where Raindrop’s Enterprise Spend Management platform provides critical value.  Raindrop takes countless data and instantly turns it into actionable information through an easy to consume visualization interface.  With Raindrop, gain spend insights in whichever format you desire – be it charts, graphs, tables, or more.  Everything is immediately accessible with a single click or two.  Raindrop redefines spend analytics by encapsulating multiple types of data, such as supplier, funding department, managing department, and much more, delivering contract analytic insights into combined or separate reports.",
    ParaFour: "The past few years have put sourcing organizations on the hot seat for ramping up (and down) complicated global supply chains.  Need an current example?  Think of the chip shortage responsible for shutting down almost every automobile manufacturer.  This is largely driven by failing to understand the analytics of how money is being contractually obligated (or in the case of automobile manufacturers, not being committed during the COVID economic slowdown in 2020) relative to future sales demand forecasts.",
    ParaFive: "Raindrop empowers the move up the value chain by leveraging several factors.  Armed with automation, advanced analytics, Machine Learning, and Artificial Intelligence, Raindrop empowers the CPO’s digitization strategy, providing a “single pane of glass” spend management solution, powerfully delivering the value of advanced analytics.",
    HeadingOne: "Here are some of Raindrop’s Advanced Spend Analytics key features",
    HeadingOneParaOne: "Move up the value chain by shifting the CPO’s Procurement organization to a “predictive sourcing” model by knowing where and how funds leave the company.  Optimize these spend categories and begin generating very real cash from operations, in turn, delivering increased margins back to your company.",
    HeadingOneParaTwo:  "Gain immediate spend visibility and advanced spend intelligence to deliver on cost reduction and supplier cost efficiency strategies, courtesy of Raindrop’s native Machine Learning and Artificial Intelligence technologies.",
    HeadingOneParaThree: "Dive into supplier spend analytics to identify various risks and opportunities.  Take advantage of Raindrop’s “call to action” and those pesky unknown automatic contractual renewals become a thing of the past.",
    HeadingOneParaFour: "Provides business intelligence in areas like contractual obligations, category management, supplier consolidation or expansion, cost containment, etc."
  },
]
